<template>
	<div :class="{ 'px-3': isSmAndDown }">
		<v-row class="mt-1 mb-2">
			<v-col class="px-0">
				<div class="d-flex align-center">
					<router-link
						:to="{
							name: 'mypage-market-order',
							params: {
								orderId: order.id,
							},
						}"
						class="text-decoration-none"
					>
						<h3 style="font-weight: 500">
							<v-icon style="position: relative; bottom: 2px">
								mdi-card-multiple-outline
							</v-icon>
							상품
						</h3>
					</router-link>
					<span class="mx-1">&#62;</span>
					<h3>결제하기</h3>
				</div>
			</v-col>
		</v-row>
		<v-row>
			<v-col cols="12" md="12" sm="12" class="pt-0 px-0">
				<v-card class="py-4 px-7">
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>결제 정보</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs">
									상품명
								</v-list-item-subtitle>
								<v-list-item-title v-if="order && order.title">
									{{ order.title }}
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-subtitle class="text-xs">이름</v-list-item-subtitle>
							<v-list-item-title>{{ profile.name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-subtitle class="text-xs">
								이메일
							</v-list-item-subtitle>
							<v-list-item-title>{{ profile.email }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
					<v-list-item two-line>
						<v-list-item-content>
							<v-list-item-subtitle class="text-xs">
								휴대폰번호
							</v-list-item-subtitle>
							<v-list-item-title>
								{{ profile.phoneNumber | krPhoneFormat }}
							</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</v-card>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="pt-0 px-0">
				<v-card class="py-4 px-7">
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>가격 정보</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-subtitle class="text-xs text-wrap">
									가격
								</v-list-item-subtitle>
								<v-list-item-title v-if="order" class="text-wrap">
									<div v-if="order.amount == 0">
										<div class="mb-5">무료</div>
										<v-btn class="button apply mb-1" color="secondary">
											신청보드에서 신청 가능합니다
										</v-btn>
									</div>
									<div v-else>
										<span class="amount primary--text">
											{{ order.amount | commaFormat }} 원
										</span>
										<span :class="{ 'd-block text-sm': isSmAndDown }">
											(교육비 24만원 + 검정료 4만원 + 교재비 1만원)
										</span>
									</div>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="pt-0 px-0">
				<v-card class="py-4 px-7">
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>포인트</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-3" />
						<v-list-item two-line>
							<v-list-item-content>
								<v-list-item-title class="text-wrap">
									<v-row>
										<v-col
											:cols="isSmAndDown ? 2 : 1"
											class="d-flex align-center justify-start"
										>
											<span>잔액</span>
										</v-col>
										<v-col :cols="isSmAndDown ? 10 : 11">
											<v-text-field
												v-model="balance"
												placeholder="0"
												hide-details
												outlined
												reverse
												readonly
												:disabled="originBalance === 0"
												class="balance"
												prefix="리워드"
											/>
										</v-col>
									</v-row>

									<v-row>
										<v-col
											:cols="isSmAndDown ? 2 : 1"
											class="d-flex align-center justify-start"
										>
											<span>사용</span>
										</v-col>
										<v-col :cols="isSmAndDown ? 10 : 11">
											<v-text-field
												v-model="rewardToUse"
												placeholder="0"
												hide-details
												outlined
												reverse
												class="rewardToUse"
												:disabled="originBalance === 0"
												@input="onInputRewardToUse"
												@keypress="allowOnlyNumber('rewardToUse', $event)"
												prefix="리워드"
											/>
										</v-col>
									</v-row>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
					</v-list>
				</v-card>
			</v-col>
			<v-col cols="12" md="12" sm="12" class="pt-0 px-0">
				<v-card class="py-4 px-7">
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>결제 수단 선택</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-4" />
						<v-list-item>
							<v-list-item-content>
								<v-radio-group hide-details v-model="paymentMethod" row>
									<v-radio
										v-for="(payment, index) in paymentGroup"
										:key="`payment-${index}`"
										:label="payment.name"
										:value="payment.value"
									>
										<template slot="label">
											<span
												class="d-flex align-center"
												v-if="
													paymentMethods.KAKAOPAY &&
													payment.value === paymentMethods.KAKAOPAY.value
												"
											>
												<img
													height="28"
													:src="
														require(`@/assets/logo/logo-${payment.value}.svg`)
													"
												/>
											</span>
											<span
												class="d-flex align-center"
												v-else-if="
													paymentMethods.NAVERPAY &&
													payment.value === paymentMethods.NAVERPAY.value
												"
											>
												<img
													height="24"
													:src="
														require(`@/assets/logo/logo-${payment.value}.png`)
													"
												/>
											</span>
											<span
												class="d-flex align-center"
												v-else-if="
													paymentMethods.PAYPAL &&
													payment.value === paymentMethods.PAYPAL.value
												"
											>
												<img
													height="62"
													:src="
														require(`@/assets/logo/logo-${payment.value}.png`)
													"
												/>
											</span>
											<span class="d-flex align-center" v-else>
												{{ payment.name }}
											</span>
										</template>
									</v-radio>
								</v-radio-group>
							</v-list-item-content>
						</v-list-item>
					</v-list>
					<v-card-text class="px-0">
						<p class="mb-0">
							일반 신용카드의 무이자 할부 개월 수(최대 12개월)는
							<strong>카드사에 따라 상이할 수 있으니, 결제창에서 확인</strong>
							해주세요.
						</p>
					</v-card-text>
					<v-card-actions class="px-0 d-flex flex-column">
						<div class="button-wrapper">
							<v-btn
								class="button apply mb-1"
								color="primary"
								@click="requestPay"
								:loading="isLoading"
								:disabled="order.paid"
							>
								결제하기
							</v-btn>
						</div>
					</v-card-actions>
					<v-list>
						<v-list-item>
							<v-list-item-content>
								<v-list-item-title>
									<h4>주의사항 및 환불규정</h4>
								</v-list-item-title>
							</v-list-item-content>
						</v-list-item>
						<v-divider class="my-4" />
					</v-list>
					<v-card-text class="px-0">
						<h4>주의사항</h4>
						<ul class="mb-5">
							<li>
								상황에 따라 사전 공지 없이 할인이 조기 마감되거나 연장될 수
								있습니다.
							</li>
							<li>
								천재지변, 폐업 등 서비스 중단이 불가피한 상황에는 서비스가
								종료될 수 있습니다.
							</li>
							<li>
								커리큘럼은 제작 과정에서 일부 추가, 삭제 및 변경될 수 있습니다.
							</li>
						</ul>
						<h4>환불규정</h4>
						<ul class="mb-2">
							<li>사전 접수 기간 중: 전액 환불</li>
							<li>
								사전접수 기간 종료 후 ~ 교육 1일 전: 등록비 5% 내 공제 후 환불
							</li>
							<li>교육 당일 및 이후: 환불 불가</li>
						</ul>
						<p>
							* 보다 자세한 환불 규정은 홈페이지 이용 약관 환불 정책에서 확인
							가능합니다.
						</p>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'

import {
	addHour,
	commaFormat,
	commaRemove,
	dateFormatUtcToKst,
	krPhoneFormat,
} from '@/filter'
import store from '@/store'
import { useRouter, getVuetify } from '@core/utils'
import { paymentMethods } from '@/helpers'
import { successSwal, warningSwal } from '@/plugins/swalMixin'

import MarketBalanceService from '@/services/MarketBalanceService'
import MarketOrderService from '@/services/MarketOrderService'
import UtilityService from '@/services/UtilityService'

const moment = require('moment')

export default {
	components: {},
	setup() {
		const $vuetify = getVuetify()
		const { route, router } = useRouter()
		const originBalance = ref(0)
		const balance = ref(0)
		const rewardToUse = ref(0)
		const order = ref({})
		const isLoading = ref(false)
		const profile = computed(() => store.getters['user/getMeDetail'])
		const orderId = route.value.params.orderId
		const paymentMethod = ref('card')
		const paymentGroup = Object.values(paymentMethods)

		const getBalance = async () => {
			try {
				const data = await MarketBalanceService.getMyMarketBalance()
				originBalance.value = commaFormat(data.balance)
				balance.value = originBalance.value
			} catch (e) {
				console.error(e)
			}
		}

		const getMarketOrder = async () => {
			try {
				const data = await MarketOrderService.getMarketOrder(orderId)

				if (!data.visible || !data.isActive) {
					router.go(-1)
					return
				}

				if (data.paid) {
					await warningSwal('이미 결제가 완료된 상품입니다')
					router.push({ name: 'mypage-license' })
				}

				order.value = data
			} catch (e) {
				await warningSwal('잘못된 요청입니다')
				router.go(-1)
			}
		}
		getMarketOrder()

		const createMarketOrderPayment = async payload => {
			try {
				await MarketOrderService.createMarketOrderPayment({
					orderId: orderId,
					payload: payload,
				})
				const confirm = await successSwal({
					title: '결제 성공',
					allowOutsideClick: false,
				})
				if (confirm.isConfirmed) {
					router.push({
						name: 'mypage-market-order-payment-complete',
					})
				}
			} catch (e) {
				warningSwal({
					title: '결제를 처리하는데 문제가 발생했습니다',
				})
			}
		}

		watch(
			() => route.value.query.imp_success,
			async currentValue => {
				if (currentValue && currentValue !== 'undefined') {
					const isSuccess = currentValue === 'true' ? true : false
					if (isSuccess) {
						await getMarketOrder()
						const payload = {
							iamportUid: route.value.query.imp_uid,
							pgResponse: JSON.stringify({}),
						}

						const _rewardToUse = commaRemove(rewardToUse.value)
						if (_rewardToUse > 0) {
							payload.pointAmount = _rewardToUse
						}
						await createMarketOrderPayment(payload)
					} else {
						await warningSwal('결제를 처리하는데 문제가 발생했습니다!')
						router.push({
							name: 'mypage-market-order-payment',
							params: { orderId: orderId },
						})
					}
				}
			},
			{
				immediate: true,
			},
		)

		const getApiServerTime = async () => {
			return await UtilityService.getApiServerTime()
		}

		const callback = async response => {
			const { success, error_msg } = response

			if (success) {
				try {
					const payload = {
						iamportUid: response.imp_uid,
						pgResponse: JSON.stringify(response),
					}

					const _rewardToUse = commaRemove(rewardToUse.value)
					if (_rewardToUse > 0) {
						payload.pointAmount = _rewardToUse
					}
					await createMarketOrderPayment(payload)
				} catch (e) {
					warningSwal({
						title: '결제 처리하는데 문제가 생겼습니다.',
						html: error_msg,
					})
				}
			} else {
				await warningSwal({
					title: '결제 실패',
					html: error_msg,
				})
				router.go()
			}
		}

		const onInputRewardToUse = $event => {
			if (parseInt(commaRemove($event)) > commaRemove(originBalance.value)) {
				warningSwal('포인트가 잔액 보다 많습니다')

				rewardToUse.value = 0
				$event.preventDefault()
			} else if (commaRemove($event) > order.value.amount) {
				warningSwal('사용 포인트가 결제 금액보다 많습니다')

				rewardToUse.value = 0
				$event.preventDefault()
			}
		}

		const requestPay = async () => {
			const _rewardToUse = commaRemove(rewardToUse.value)
			if (order.value.amount === _rewardToUse) {
				try {
					const payload = {
						pointAmount: _rewardToUse,
					}
					await createMarketOrderPayment(payload)
				} catch (e) {
					console.error(e)
				}

				return
			}

			isLoading.value = true
			try {
				const { IMP } = window

				IMP.init(process.env.VUE_APP_MERCHANT_ID_CODE)

				const data = {
					pg:
						paymentMethod.value === paymentMethods.PAYPAL.value
							? paymentMethods.PAYPAL.value
							: process.env.VUE_APP_PG_CODE,
					pay_method:
						paymentMethod.value === paymentMethods.PAYPAL.value
							? paymentMethods.CARD.value
							: paymentMethod.value,
					escrow: undefined,
					merchant_uid: order.value.uuid,
					name: order.value.title,
					amount: order.value.amount - commaRemove(rewardToUse.value),
					buyer_name: profile.value.name,
					buyer_tel: krPhoneFormat(profile.value.phoneNumber),
					buyer_email: profile.value.email,
					buyer_addr: '',
					buyer_postcode: '',
					niceMobileV2: true,
					m_redirect_url:
						process.env.VUE_APP_DOMAIN_URL +
						`/mypage/market-order/${orderId}/payment`,
				}

				if (paymentMethod.value === paymentMethods.CARD.value) {
					data.display = {
						card_quota: [2, 3, 4, 5, 6, 7, 8],
					}
				}

				if (paymentMethod.value === paymentMethods.PAYPAL.value) {
					data.currency = 'USD'
				}

				if (paymentMethod.value === 'vbank') {
					const now = await getApiServerTime()
					const formatNow = moment.utc(now).format()
					const kstNow = dateFormatUtcToKst(formatNow)
					data.vbank_due = addHour(kstNow, 1, 'YYYYMMDDHHmm')
				}
				IMP.request_pay(data, callback)
			} catch (e) {
				console.log(e)
			} finally {
				isLoading.value = false
			}
		}

		const allowOnlyNumber = (inputType, $event) => {
			const keyCode = $event.keyCode ? $event.keyCode : $event.which
			if (keyCode < 48 || keyCode > 57) {
				$event.preventDefault()
			}

			if ($event.target.value > 0) {
				if (inputType === 'balance') {
					balance.value = parseInt($event.target.value)
				} else if (inputType === 'rewardToUse') {
					rewardToUse.value = parseInt($event.target.value)
				}
			}
		}

		const isSmAndDown = computed(() => {
			return $vuetify.breakpoint.smAndDown
		})

		watch(
			() => rewardToUse.value,
			currentValue => {
				const _balance =
					commaRemove(originBalance.value) - commaRemove(currentValue)

				if (_balance < 0) {
					warningSwal('포인트가 잔액 보다 많습니다')
					return
				} else {
					balance.value = commaFormat(_balance)
					rewardToUse.value = commaFormat(currentValue)
				}
			},
		)

		onMounted(() => {
			getBalance()
		})

		return {
			order,
			profile,
			balance,
			isSmAndDown,
			originBalance,
			rewardToUse,
			isLoading,
			paymentMethod,
			paymentGroup,
			paymentMethods,

			requestPay,
			allowOnlyNumber,
			onInputRewardToUse,
		}
	},
}
</script>
<style lang="scss" scoped>
.v-list-item {
	padding: 0;

	&__subtitle {
		color: #aaaaaf !important;
	}

	&__title {
		span.body-content {
			::v-deep {
				p {
					margin-bottom: 2px;
				}
			}
		}
	}

	span.amount {
		font-size: 24px;
		font-weight: 900;
		letter-spacing: -0.5px;
	}
}

.v-text-field.rewardToUse input,
.v-text-field.balance input {
	font-weight: 900;
}

.button-wrapper {
	width: 100%;
}

.button {
	display: block;
	width: 100%;
	max-width: 100%;
	min-height: 48px;
	margin-left: 0 !important;
	font-weight: 900;

	::v-deep {
		.v-btn__content {
			height: 100% !important;
		}
	}
}
</style>
